<template>
    <div class="list-page">
        <div class="wrap">
            <img class="banner" :src="banner"/>
            <div class="activity-name">
                <el-divider>
                    <i class="el-icon-reading"></i>
                    <span>{{activityName}}</span>
                </el-divider>
            </div>
            <div class="course-list z-flex">
                <courseItem
                        v-for="(item,index) in rowList"
                        :key="index"
                        :itemData=item
                >
                </courseItem>
            </div>
            <div class="page-wrap">
                <el-pagination
                        layout="prev, pager, next"
                        :total="rowTotal"
                        :current-page="currentPage"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :hide-on-single-page="true"
                >
                </el-pagination>
            </div>
            <div class="z-flex z-flex-col z-flex-center" style="padding:30px 0;" v-if="!vuex_user">
                <img style="display:block;width:200px;" src="../assets/images/20220728/default_login.png"/>
                <p style="color:#777;font-size:18px;margin-top:20px;">会员请先登录</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import courseItem from "../components/courseItem"

    export default {
        components: {
            courseItem,
        },
        data() {
            return {
                currentPage: 1, //当前页数
                pageSize: 20, //每页20条
                rowTotal: 0,  //总数
                rowList: [], //列表
                activityName:'大扬年度会员',
                banner:'https://dyyxclub-files.oss-cn-hangzhou.aliyuncs.com/images/LFIkf14KhPWhp7xQwhAPwVRpZ3aRm6LjQ8BCRzyk.jpeg',
            };
        },
        computed: {
            ...mapState(['vuex_user'])
        },
        watch: {
            vuex_user: {
                handler(newVal, oldVal) {
                    if (oldVal == null && newVal != null) {
                        this.getRows();
                    }
                },
                deep: true
            }
        },
        mounted() {
            if (!this.vuex_user) {
                //登录窗口
                this.$parent.$refs.navcom.login();
            } else {
                this.getRows();
            }
        },
        methods: {
            //分页
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.getRows();
            },
            //获取数据
            getRows() {
                let params = {
                    id: this.$route.query.id,
                    page: this.currentPage,
                    page_size: this.pageSize,
                };
                let that = this;
                this.$axios.api.activity(params, function (res) {
                    if (res.code == 200) {
                        console.log(res);
                        that.rowTotal = res.data.courses.total;
                        that.rowList = res.data.courses.list;
                        that.activityName = res.data.activity.name;
                        that.banner = res.data.activity.banner;
                    } else {
                        console.log(res.msg);
                    }
                });
            }
        },
    };
</script>

<style lang="scss" scoped>
    .banner{
        margin-top: 20px;
        width: 1200px;
        height: 400px;
        object-fit: cover;
    }
    .activity-name{
        margin: 60px 0;
        ::v-deep .el-divider__text{
            font-size: 32px;
            background-color: #eff3f5;
            font-weight: 700;
        }
        i{
          font-size: 32px;
          margin-right: 10px;
        }
    }
    .course-list {
        margin: 30px 0 20px;

        .course-item {
            margin: 0 20px 20px 0;

            &:nth-child(4n) {
                margin: 0 0 20px 0;
            }
        }
    }
</style>
